<template>
  <v-card class="accounts-strategy">
    <v-card-title>Protocol Strategy</v-card-title>
    <graph
      :options="chartOptions"
      width="400px"
      height="200px"
      :series="series"
    />
  </v-card>
</template>
<script>
import Graph from '@/components/graph.vue'
export default {
  components: {
    Graph
  },
  data () {
    return {
      chartOptions: {
        chart: {
          type: 'bar'
        },
        labels: [
          'Relance préventive 2',
          'Appel téléphonique 1',
          'Arbitrage Contentieux'
        ],
        title: {
          text: 'Clients Occasionnels et A risque'
        },
        plotOptions: {
          bar: {
            endingShape: 'rounded',
            horizontal: true
          }
        },
        xaxis: {
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          }
        }
      },
      series: [{
        name: '',
        data: [6, 4, 86]
      }]
    }
  }
}
</script>
